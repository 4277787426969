export default function auth () {
  return {
    user_email: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[A-ZА-Я0-9._%+\-]+@[A-ZА-Я0-9.\-]+\.[A-ZА-Я]{2,4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    user_password: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length >= 4,
        message: 'Должно быть больше 4 символов'
      }
    }
  }
}
