import { Turbo } from "@hotwired/turbo-rails"
import { default as phoneMasking } from 'src/application/forms_validations/rules/phone_masking'
import formsValidations from 'src/application/forms_validations'
import notExistBind from 'src/application/not_exist_binding'
window.Turbolinks = Turbo

// Turbo.scrollTop = 0
// Turbo.shouldPreserveScroll = false
// let shouldPreserveScroll = 0
//
// const scrollEvents = () => {
//   // const elements = document.querySelectorAll('[data-turbolinks-scroll]')
//
//   document.addEventListener('turbo:click', event => {
//     shouldPreserveScroll = !!event.target.hasAttribute('data-turbo-preserve-scroll')
//   })
//
//   document.addEventListener('turbo:visit', event => {
//     if (shouldPreserveScroll)
//       Turbo.scrollTop = document.documentElement.scrollTop
//     else
//       Turbo.scrollTop = 0
//   })
//
//   addEventListener('turbo:visit', () => {
//     Turbo.navigator.currentVisit.scrolled = true
//     document.documentElement.scrollTop = Turbo.scrollTop
//   })
// }

const reloadLibs = () => {
  window.phoneMasking = phoneMasking
  window.formsValidations = formsValidations
  window.notExistBind = notExistBind

  formsValidations()
  phoneMasking()
  notExistBind()
}

const removeLoader = () => {
  // scrollEvents()
  reloadLibs()

  const serverLoader = document.getElementById('server_loader')
  const logoSpinner = serverLoader.querySelector('div')

  if (!serverLoader) return false

  if (serverLoader.classList.contains('is-paused')) {
    serverLoader.classList.remove('is-paused')
    setTimeout(() => logoSpinner?.remove(), 0)
  } else {
    serverLoader.style.opacity = '0.5'
    logoSpinner?.remove()
    // serverLoader.remove()
  }

  if (serverLoader.classList.contains('clicked')) {
    serverLoader.classList.remove('clicked')
    logoSpinner?.remove()
  }

  highlightLinks()
  setTimeout(pageFinishTurn, 0)
}

const startFade = () => {
  const serverLoader = document.getElementById('server_loader')

  if (!serverLoader) return false
  serverLoader.querySelector('div')?.remove()

  if (!serverLoader.classList.contains('is-paused')) {
    serverLoader.classList.add('is-paused')
    serverLoader.classList.add('clicked')
  }

  pageStartTurn()
}

const pageStartTurn = () => {
  const mainPage = document.getElementById('main_content')

  mainPage.classList.add('turn-start')
}

const pageFinishTurn = () => {
  const mainPage = document.getElementById('main_content')

  mainPage.classList.remove('unturned')
}

const highlightLinks = () => {
  document.querySelectorAll('.active_link').forEach(prev => {
    prev.classList.remove('active_link')
    prev.parentNode.classList.remove('active_link')
  })

  const path = window.location.pathname
  if (!path || path === '/') return

  const activeLink = document.querySelector(`a[href="${path}"]`)

  if (!activeLink) {
    document.querySelectorAll('a').forEach(link => {
      if (link.pathname !== '/' && path.indexOf(link.pathname) > -1) setActive(link)
    })
  } else { setActive(activeLink) }
}

const setActive = link => {
  link.classList.add('active_link')
  const wrapper = link.parentNode
  if (!wrapper) return

  wrapper.classList.add('active_link')
  if (!('menu_level' in wrapper.dataset)) return

  let menuLevel = parseInt(wrapper.dataset.menu_level, 10)
  if (menuLevel === 0) return

  while (menuLevel >= 0) {
    menuLevel--
    const prevParent = wrapper.closest(`li[data-menu_level="${menuLevel}"]`)
    if (prevParent) prevParent.classList.add('active_link')
  }
}

document.addEventListener('turbo:click', startFade)
document.addEventListener('turbo:load', removeLoader)
// document.addEventListener('turbolinks:before-render', removeLoader)
document.addEventListener('turbo:render', removeLoader)

// HACK: This is here because when you click on the `back` in the browser the category card won't load
window.addEventListener('popstate', e => {
  const state = e.state

  if (state !== null) {
    // Turbo.clearCache()
    Turbo.visit(location)
  }
}, false)
