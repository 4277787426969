export default function fast_buy () {
  return {
    order_user_phone: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[+]?[0-9]{1,5}[\-\s]?[\(]?[0-9]{1,5}[\)]?[-\s.\/0-9]{4,15}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    order_user_email: {
      isValid: {
        check: element => element.value.length > 0 &&
          /^[A-ZА-Я0-9._%+-]+@[A-ZА-Я0-9.-]+\.[A-ZА-Я]{2,4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    }
  }
}
